'use client';

import type { User } from 'firebase/auth';
import { signInAnonymously } from 'firebase/auth';
import { usePathname } from 'next/navigation';
import { signOut } from 'next-auth/react';
import { useEffect } from 'react';
import { getTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { firebaseAuth } from '~/lib/firebase';
import { signInNextAuthFromFirebaseUser } from '~/usecases/auth-use-case/sign-in-next-auth-from-firebase-user';
import { useAfterAuthRedirect } from '@app/auth/_hooks/use-after-auth-redirect';

type Props = {
  children?: React.ReactNode;
};

export const FirebaseAuthProvider = ({ children }: Props) => {
  const pathname = usePathname();

  // pathnameが'/auth/'で始まる場合はFirebaseAuthProviderを返さない（ログイン処理中にFirebaseAuthProviderの処理をさせたくないため）
  if (!pathname || pathname.startsWith('/auth/')) {
    return <>{children}</>;
  }

  return <_FirebaseAuthProvider>{children}</_FirebaseAuthProvider>;
};

const _FirebaseAuthProvider = ({ children }: Props) => {
  const { redirectionPath } = useAfterAuthRedirect();

  // Ensure firebase user
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleOnAfterFirebaseSignIn = async (firebaseUser: User) => {
      try {
        const currentSession = await getTellerSession();
        const afterLoginPath = decodeURIComponent(redirectionPath ?? '/');

        // User at client, session in next-auth, but info don't match
        if (
          currentSession &&
          firebaseUser &&
          (firebaseUser.uid !== currentSession.uid ||
            (firebaseUser.email && firebaseUser.email !== currentSession.email))
        ) {
          // sign out (old session)
          await signOut({ redirect: false });

          // sign in (new session)
          await signInNextAuthFromFirebaseUser(firebaseUser, undefined, {
            redirect: true,
            callbackUrl: afterLoginPath,
          });
        } else if (!currentSession) {
          // sign in (new session)
          await signInNextAuthFromFirebaseUser(firebaseUser, true, {
            redirect: true,
            callbackUrl: afterLoginPath,
          });
        }
      } catch (error) {
        // nop
        console.log(error);
      }
    };

    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      if (user) {
        handleOnAfterFirebaseSignIn(user);
      } else {
        if (!user) {
          try {
            const credential = await signInAnonymously(firebaseAuth);
            handleOnAfterFirebaseSignIn(credential.user);
          } catch (error) {
            // nop
            console.log(error);
          }
        }
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};
